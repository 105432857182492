<template>
    <el-drawer
        title="权限列表"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                    
                    <div class="form-body">
                        <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                            <el-tree
                            ref="tree"
                            :data="tableData"
                            show-checkbox
                            node-key="id"
                            :default-checked-keys="form.menus"
                            :render-after-expand="false"
                            :props="{children: 'children', label: 'title'}">
                            </el-tree>
                        </overlay-scrollbars>
                        
                    </div>

                    <div class="form-foot d-flex a-center j-center">
                        <el-button @click="onSubmit" type="primary" class="" style="width:200px">提交</el-button>
                    </div>
                </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {tree,role} from '@/request/api/menus'
import {menus} from '@/request/api/role'
export default {
    data(){
        return {
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                roleId:null,
                menus:[]
            },
            tableData:[],
            rules:{
                nickname:[
                    { required: true, message: '请输入设备名称', trigger: 'blur' },
                ],
            }
        }
    },
    
    mounted(){
        this.form.roleId = this.$route.query.id || null
        this.init()
    },
    methods:{
        async init(){
            let d = await role({roleId:this.form.roleId})
            let {data} =await tree()
            var arr = [];
            d.data.forEach(item=>{
                arr.push(item.id)
            })
            this.tableData = data;
            let allParentNodes = this.getParentKeys()
            this.form.menus = arr.filter(item=>!allParentNodes.includes(item));
        },
        
        //所有父节点id
        getParentKeys(){
            var arr = []
            this.tableData && this.tableData.forEach(item=>{
                if(item.children && item.children.length) {
                    arr.push(item.id)
                    item.children.forEach(itx=>{
                        if(itx.children && itx.children.length) {
                            arr.push(itx.id)
                        }
                    })
                }
            })
            return arr;
        },

        // 获取所有的复选框已经勾选的id
        getAllCheckedKeys() {
            const defaultCheckedKeys = [];
            // 获取ref
            const treeDataRef = this.$refs.tree;
            const treeDataList = [...treeDataRef.getCheckedNodes(), ...treeDataRef.getHalfCheckedNodes()];
            treeDataList.forEach(item=>{
                defaultCheckedKeys.push(item.id)
            })
            return defaultCheckedKeys;
        },

        // 对半选和全选的进行反选
        async initCheckoutTree() {
            await this.$nextTick();
            const treeDataRef = this.$refs.tree;
            for (const key of this.defaultCheckedKeys) {
                const node = treeDataRef.getNode(key);
                if (node?.isLeaf) {
                    treeDataRef.setChecked(node, true);
                }
            }
        },


        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        
        //req
        req(){
            this.form.menus = this.getAllCheckedKeys()
            menus(this.form).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message: res.msg,type: 'error'});
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});  
                this.$router.go(-1)
            })
        },



        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}

</style>

